import { HttpService } from '@/services/http.service';
import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import Login from '../views/Login.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/adminoverview',
    name: 'AdminOverview',
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/AdminOverview.vue'),
  },
  {
    path: '/commoditylist',
    name: 'CommodityList',
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/CommodityList.vue'),
  },
  {
    path: '/customerlist',
    name: 'CustomerList',
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/CustomerList.vue'),
  },
  {
    path: '/operationcard',
    name: 'OperationCard',
    component: () => import('../views/OperationCard.vue'),
  },
  {
    path: '/operationlist',
    name: 'OperationList',
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/OperationList.vue'),
  },

  {
    path: '/ordercalendar',
    name: 'OrderCalendar',
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/OrderCalendar.vue'),
  },
  {
    path: '/ordersofcustomer',
    name: 'OrdersOfCustomer',
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/OrdersOfCustomer.vue'),
  },
  {
    path: '/ordersonshift',
    name: 'OrdersOnShift',
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/OrdersOnShift.vue'),
  },
  {
    path: '/terminallist',
    name: 'TerminalList',
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/TerminalList.vue'),
  },
  {
    path: '/configurationlist',
    name: 'ConfigurationList',
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/ConfigurationList.vue'),
  },
  {
    path: '/storageview',
    name: 'StorageView',
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/StorageView.vue'),
  },
  {
    path: '/statisticsview',
    name: 'StatisticsView',
    component: () => import('@/views/StatisticsView.vue'),
  },
  {
    path: '/maintenanceview',
    name: 'MaintenanceView',
    component: () => import('@/views/MaintenanceView.vue'),
  },
  {
    path: '/logisticsview',
    name: 'LogisticsView',
    component: () => import('@/views/LogisticsView.vue'),
  },
  {
    path: '/warehouseoverview',
    name: 'WarehouseOverview',
    component: () => import('@/views/WarehouseOverview.vue'),
  },
];

const router = new VueRouter({
  routes,
});

// console.log({
//   authorizedForRoutes: authorizedForRoutes,
//   toName: to.name,
//   role: role,
//   index: authorizedForRoutes[to.name].indexOf(role),
// });
router.beforeEach(
  async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
    if (to.name === 'Login') {
      next();
      return;
    } else {
      if (!HttpService.isAuthenticated()) {
        next({ name: 'Login' });
        return;
      } else {
        const authorizedForRoutes: { [key: string]: string[] } = {
          AdminOverview: ['Administration'],
          CommodityList: ['Administration'],
          ConfigurationList: ['Administration'],
          CustomerList: ['Administration'],
          LogisticsView: ['Administration'],
          MaintenanceView: ['Administration'],
          OperationCard: [
            'Administration',
            'Production',
            'WarehouseManagement',
          ],
          OperationList: [
            'Administration',
            'Production',
            'WarehouseManagement',
          ],
          OrderCalendar: ['Administration', 'WarehouseManagement'],
          OrdersOfCustomer: ['Administration', 'WarehouseManagement'],
          OrdersOnShift: ['Administration', 'WarehouseManagement'],
          StatisticsView: ['Administration'],
          StorageView: ['Administration', 'WarehouseManagement'],
          TerminalList: ['Administration'],
          WarehouseOverview: ['WarehouseManagement'],
        };
        const role = HttpService.getTerminalRole();
        if (to.name !== undefined && to.name !== null) {
          if (authorizedForRoutes[to.name].indexOf(role) !== -1) {
            next();
            return;
          }
        }
      }
      next({ name: 'Login' });
    }
  }
);

const DEFAULT_TITLE = 'TDT Manufacture';
router.afterEach((to, _) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta?.title || DEFAULT_TITLE;
  });
});

export default router;
